import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"
import LazyLoad from "react-lazyload"

import "./blog-post.css"

const BlogPost = props => {
  const { data } = props
  const {
    title,
    body,
    heroImage,
    tags,
    publishDate,
    description: { description },
  } = data.post
  const { next, previous } = data
  return (
    <Layout>
      <Helmet>
        <title>{title} | blog | Mostafa Darehzereshki</title>
        <meta name="description" content={description} />
      </Helmet>
      <article className="BlogPost">
        <header>
          <h1>{title}</h1>
          {heroImage && (
            <LazyLoad
              width={`${Math.min(
                700,
                typeof window !== `undefined` ? window.innerWidth : 0
              )}px`}
            >
              <img
                alt={title}
                src={heroImage.file.url}
                className="BlogPost__image"
              />
            </LazyLoad>
          )}
        </header>
        <main>
          <ReactMarkdown source={body.body} />
        </main>
        <footer>
          <div className="BlogPost__header-first-row">
            <time
              dateTime={publishDate}
              className="PostThumbnail__header__date"
            >
              {new Date(publishDate).toLocaleDateString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </time>
            <div className="BlogPost__tag-container">
              {tags.map((t, index) => (
                <span className="BlogPost__tag" key={`tags-${t}`}>
                  #{t}
                </span>
              ))}
            </div>
          </div>
          <hr />
          <div
            className="BlogPost__footer__btns"
            style={{
              justifyContent:
                previous && next
                  ? "space-between"
                  : previous
                  ? "flex-start"
                  : "flex-end",
            }}
          >
            {previous && (
              <Link
                to={`/blog/${previous.slug}`}
                className="PostThumbnail__link PostThumbnail__link--bold"
              >
                <span className="Link__arrow">&#8592;</span>
                {previous.title}
              </Link>
            )}
            {next && (
              <Link
                to={`/blog/${next.slug}`}
                className="PostThumbnail__link PostThumbnail__link--bold"
              >
                {next.title}
                <span className="Link__arrow">&#8594;</span>
              </Link>
            )}
          </div>
        </footer>
      </article>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($slug: String!, $prev: String, $next: String) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      body {
        body
      }
      heroImage {
        file {
          url
        }
      }
      publishDate
      tags
    }
    next: contentfulBlogPost(slug: { eq: $next }) {
      title
      slug
    }

    previous: contentfulBlogPost(slug: { eq: $prev }) {
      title
      slug
    }
  }
`
